/** Global Style ------------------- **/


::-webkit-scrollbar {
  width: clamp(8px,1vmin,30px);
}

::-webkit-scrollbar-track {
  background: #dcdcdc;
  border-radius: 15px
}

::-webkit-scrollbar-thumb {
  background-color: #ff9f3b;
  border-radius: 0;
  padding: 0
}

::-webkit-scrollbar-thumb:hover {
  background: gray
}
  
.text-rainbow {background-image: linear-gradient(122deg,#f17b85,#f79393 15%,#fee4a5 45%,#9ae8b0 65%,#5ba5d0 90%,#9078d0);-webkit-mask-image: linear-gradient(122deg,#f17b85,#f79393 15%,#fee4a5 45%,#9ae8b0 65%,#5ba5d0 90%,#9078d0);-webkit-box-decoration-break: clone;-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
span.standout {background: -webkit-linear-gradient(var(--primary-light),var(--primary));-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.uxd-subtitle {text-transform: uppercase;order: -1;font-size: var(--text-l);font-weight: 700;background-color: #FFE53B;background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);;-webkit-mask-image: linear-gradient(50deg, rgba(137,18,206,1) 30%, rgba(58,199,212,1) 100%);-webkit-box-decoration-break: clone;-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.span-subtitle {background: rgb(137,18,206);background: linear-gradient(50deg, rgba(137,18,206,1) 30%, rgba(58,199,212,1) 100%);-webkit-mask-image: linear-gradient(50deg, rgba(137,18,206,1) 30%, rgba(58,199,212,1) 100%);-webkit-box-decoration-break: clone;-webkit-background-clip: text;-webkit-text-fill-color: transparent; }
.uxd-divider {height: 4px;width: 80%;border-radius: var(--rad-m);background: rgb(137,18,206);background: linear-gradient(50deg, rgba(137,18,206,1) 30%, rgba(58,199,212,1) 100%);-webkit-mask-image: linear-gradient(50deg, rgba(137,18,206,1) 30%, rgba(58,199,212,1) 100%);-webkit-box-decoration-break: clone;}
.order-one {order: -1;}
main .prefooter-category a:hover {text-decoration: underline;}
.uxd-img {max-width: 100%;height: auto;}
.uxd-img-alpha-shadow {object-fit: cover;width: 100%;aspect-ratio: auto;box-shadow: 0px 30px 40px -10px rgba(0,0,0,0.5);}

.rank-math-breadcrumb {font-size: var(--text-s)!important;}

main .prefooter-category a:hover {text-decoration: underline;}
figure {margin:inherit}
  
.relative { position: relative;}
.absolute { position: absolute;}

.vc-img, .uxd-img, .uxd-img-wrp img, img { max-width: 100%; height: auto; width: 100%;}
  
section.full-width {width:100%; display: flex; Justify-content: center;}

button {width: 100%;
  text-align: left;
  background: inherit;
  border: none;
  cursor: pointer;}
  
/* ! size and space */
/* ! Grid */
/*grid base*/
.grid-2 {display: grid!important; grid-template-columns: repeat(2, 1fr); width: 100%;}
.grid-3 {display: grid!important; grid-template-columns: repeat(3, 1fr); width: 100%;}
.grid-4 {display: grid!important; grid-template-columns: repeat(4, 1fr); width: 100%;}
.grid-5 {display: grid!important; grid-template-columns: repeat(5, 1fr); width: 100%;}
.grid-6 {display: grid!important; grid-template-columns: repeat(6, 1fr); width: 100%;}
  
/* grid 2-1 */
.grid-2-1 {display: grid!important; grid-template-columns: 2fr 1fr;} 
.grid-1-2 {display: grid!important; grid-template-columns: 1fr 2fr;} 
  
/* ! Grid-1-3 */
.grid-1-3 {display: grid!important; grid-template-columns: 1fr 3fr; align-items: stretch;} 
.grid-3-1 {display: grid!important; grid-template-columns: 3fr 1fr; align-items: stretch;} 
  
/**/
.grid-2-3 {display: grid!important; grid-template-columns: 2fr 3fr; align-items: stretch;} 
  
.gap--xs {gap: var(--space-xs);}
.gap--s {gap: var(--space-s);}
.gap--m {gap: var(--space-m);}
.gap--l {gap: var(--space-l);}
.gap--xl {gap: var(--space-xl);}
.gap--xxl {gap: var(--space-xxl);}
.gap-20 {gap: 20px}

.row-gap--xs {row-gap: var(--space-xs);}
.row-gap--s {row-gap: var(--space-s);}
.row-gap--m {row-gap: var(--space-m);}
.row-gap--l {row-gap: var(--space-l);}
.row-gap--xl {row-gap: var(--space-xl);}
.row-gap--xxl {row-gap: var(--space-xxl);}

.column-gap--xs {column-gap: var(--space-xs);}
.column-gap--s {column-gap: var(--space-s);}
.column-gap--m {column-gap: var(--space-m);}
.column-gap--l {column-gap: var(--space-l);}
.column-gap--xl {column-gap: var(--space-xl);}
.column-gap--xxl {column-gap: var(--space-xxl);}
  
/**margin**/
.mt-xxl,.margin-top-xxl {margin-top: var(--space-xxl)!important;}
.mt-xl,.margin-top-xl {margin-top: var(--space-xl)!important;}
.mt-l,.margin-top-l {margin-top: var(--space-l)!important;}
.mt-m,.margin-top-m {margin-top: var(--space-m)!important;}
.mt-s,.margin-top-s {margin-top: var(--space-s)!important;}
.mt-s,.margin-top-xs {margin-top: var(--space-xs)!important;}
  
.mb-xxl,.margin-bot-l {margin-bottom: var(--space-xxl)!important;}
.mb-xl,.margin-bot-l {margin-bottom: var(--space-xl)!important;}
.mb-l,.margin-bot-l {margin-bottom: var(--space-l)!important;}
.mb-m,.margin-bot-m {margin-bottom: var(--space-m)!important;}
.mb-s,.margin-bot-s {margin-bottom: var(--space-s)!important;}
.mb-xs,.margin-bot-xs {margin-bottom: var(--space-xs)!important;}

.mx-xxl {margin-left: var(--space-xxl)!important; margin-right: var(--space-xxl)!important;}
.mx-xl {margin-left: var(--space-xl)!important; margin-right: var(--space-xxl)!important;}
.mx-l {margin-left: var(--space-l)!important; margin-right: var(--space-xxl)!important;}
.mx-m {margin-left: var(--space-m)!important; margin-right: var(--space-xxl)!important;}
.mx-s {margin-left: var(--space-s)!important; margin-right: var(--space-xxl)!important;}
.mx-xs {margin-left: var(--space-xs)!important; margin-right: var(--space-xxl)!important;}

.mr-xxl {margin-right: var(--space-xxl)!important;}
.mr-xl {margin-right: var(--space-xxl)!important;}
.mr-l {margin-right: var(--space-xxl)!important;}
.mr-m {margin-right: var(--space-xxl)!important;}
.mr-s {margin-right: var(--space-xxl)!important;}
.mr-xs {margin-right: var(--space-xxl)!important;}

.bt-s {border-top:1px solid var(--grey-dark)}
  
.pt-zero,.padding-top-zero {padding-top: 0px!important;}
.pt-xxl,.padding-top-xxl {padding-top: var(--space-xxl)!important;}
.pt-xl,.padding-top-xl {padding-top: var(--space-xl)!important;}
.pt-l,.padding-top-l {padding-top: var(--space-l)!important;}
.pt-m,.padding-top-m {padding-top: var(--space-m)!important;}
.pt-s,.padding-top-s {padding-top: var(--space-s)!important;}
.pt-xs,.padding-top-xs {padding-top: var(--space-xs)!important;}
.pt-xs,.padding-top-xs {padding-top: var(--space-xs)!important;}
.pb-xxl, .padding-bot-xxl {padding-bottom: var(--space-xxl)!important;}
.pb-xl, .padding-bot-xl {padding-bottom: var(--space-xl)!important;}
.pb-l, .padding-bot-l {padding-bottom: var(--space-l)!important;}
.pb-m, .padding-bot-m {padding-bottom: var(--space-m)!important;}
.pb-s, .padding-bot-s {padding-bottom: var(--space-s)!important;}
.pb-xs, .padding-bot-xs {padding-bottom: var(--space-xs)!important;}
  
.pad-xxl {padding: var(--space-xxl)!important;}
.pad-xl {padding: var(--space-xl)!important;}
.pad-l {padding: var(--space-l)!important;}
.pad-m {padding: var(--space-m)!important;}
.pad-s {padding: var(--space-s)!important;}
.pad-xs {padding: var(--space-xs)!important;}
  
.rad-xl {border-radius: var(--rad-xl);}
.rad-l {border-radius: var(--rad-l);}
.rad-s {border-radius: var(--rad-s);}
.rad-m {border-radius: var(--rad-m);}
.rad-bottom-xl {border-bottom-left-radius: var(--rad-xl);border-bottom-right-radius: var(--rad-xl);}
  
/** display variation **/
.flex-column {display: flex; flex-direction: column; width: 100%; align-items: flex-start;}
.flex-column-center {display: flex; flex-direction: column; width: 100%; align-items: center;}
.flex-row {display: flex; flex-direction: row; width: 100%; justify-content: flex-start;}
.flex-wrap {display: flex; flex-wrap: wrap; width: 100%; justify-content: flex-start;}
.flex {display: flex; width: 100%;}
.flex-base {display: flex;}
.direction-column {flex-direction: column;}
.flex-center-all {display:flex;align-items:center;justify-content: center;text-align: center;}
.center-all {align-items: center;justify-content: center;text-align: center;}
.wrap {flex-wrap: wrap!important;}
.inline-flex {display: inline-flex!important}
  
.justify-center {justify-content: center!important;}
.align-center, .align-items-center {align-items: center!important;}
.align-items-top {align-items: flex-start!important;}
.align-items-bottom {align-items: flex-end!important;}
.align-items-flex-end, .align-i-fe {align-items: flex-end!important;}
.align-items-flex-start, .align-i-fs { align-items: flex-start!important;}
.align-items-stretch{align-items:stretch}
.justify-center, .justify-content-center {justify-content: center!important;}
.justify-flex-start {justify-content: flex-start!important;}
.justify-space-between {justify-content: space-between!important;}

/* text */
.text-h2 {font-size: var(--h2)!important;line-height: 1.2;}
.text-h3 {font-size: var(--h3)!important;line-height: 1.2;}
.text-h4 {font-size: var(--h4)!important;line-height: 1.2;}
.text-h5 {font-size: var(--h5)!important;line-height: 1.2;}
.text-h6 {font-size: var(--h6)!important;line-height: 1.2;}
.text-xxl {font-size: var(--text-xxl)!important}
.text-xl, .has-x-large-font-size {font-size: var(--text-xl)!important}
.text-l, .has-large-font-size {font-size: var(--text-l)!important}
.text-m, .has-medium-font-size {font-size: var(--text-m)!important}
.text-s, .has-small-font-size {font-size: var(--text-s)!important}
.text-xs {font-size: var(--text-xs)!important}
.text-heading {font-size: var(--h4)!important;font-weight: 600;}
  
.f-w3 {font-weight: 300!important;}
.f-w4 {font-weight: 400!important;}
.f-w5 {font-weight: 500!important;}
.f-w6 {font-weight: 600!important;}
.f-w7 {font-weight: 700!important;}
.f-w8 {font-weight: 800!important;}
  
.bb-s {border-bottom:1px solid var(--grey)}
.bt-s {border-top:1px solid var(--grey)}
  
.bl-m {border-left:2px solid var(--grey)}
  
.vh-50 {min-height: 50vh;}
.vh-60 {min-height: 60vh;}
.vh-70 {min-height: 70vh;}
.vh-100 {min-height: 100vh;}
  
.text-left {text-align: left;width: 100%;}
.text-right {text-align: right;width: 100%;}
.text-center {text-align:center;width: 100%;}
  
.text-white {color: var(--white);}
.text-dark, .text-black {color: var(--black)}
.text-button {color: var(--button);}
.text-grey {color: var(--grey-dark);}
.text-grey-light {color: var(--grey-light);}
.text-grey-dark {color: var(--grey-dark);}
.text-fff, .text-fff a {color: #fff}
.text-fff a:hover {color: #e2e2e2}
.text-000, .text-000 a {color: #000}
.text-primary {color: var(--primary);}
.text-secondary {color: var(--secondary);}

.hover-black:hover {color:#333!important}
  
  
.bg-grey {background: var(--bg-grey);}
.bg-grey-light {background: var(--grey-light);}
.bg-grey-ultra-light {background: var(--grey-ultra-light);}
.bg-grey-dark {background: var(--grey-dark);}
.bg-black {background: var(--black);}
.bg-white {background: var(--white);}
.bg-primary {background: var(--primary)}
.bg-secondary {background: var(--secondary)}
.bg-button {background: var(--button)}

.bg-site {background: var(--site-bg)}
.bg-site-secondary {background: var(--site-bg-secondary)}
  
.uppercase {text-transform:uppercase!important;}
.italic {font-style:italic;}
.underline {text-decoration:underline;}

.over-y-scroll {overflow-y:scroll}
.over-hidden {overflow: hidden;}
/**/
.w-auto {width: auto!important;} 
.m-i-auto {margin-inline: auto;}
.m-w-900 {width: 100%;max-width: 900px!important;}
.m-w-800 {width: 100%;max-width: 800px!important;}
.m-w-700 {width: 100%;max-width: 700px!important;}
.m-w-600 {width: 100%;max-width: 600px!important;}
.m-w-520 {width: 100%;max-width: 520px!important;}
.m-w-500 {width: 100%;max-width: 500px!important;}
.m-w-450 {width: 100%;max-width: 450px!important;}
.m-w-400 {width: 100%;max-width: 400px!important;}
.m-w-300 {width: 100%;max-width: 300px!important;}
.m-w-200 {width: 100%;max-width: 200px!important;}
.m-w-150 {width: 100%;max-width: 150px!important;}
.m-w-100 {width: 100%;max-width: 100px!important;}
  
.m-w-400-a {width: 100%;max-width: 400px!important;margin-inline: auto;}  
.m-w-300-a {width: 100%;max-width: 300px!important;margin-inline: auto;}
.m-w-500-a {width: 100%;max-width: 500px!important;margin-inline: auto;}
.m-w-900-a {width: 100%;max-width: 900px!important;margin-inline: auto;}
.m-w-1100-a {width: 100%;max-width: 1100px!important;margin-inline: auto;}
.min-h-500 {min-height: 500px;}
.min-h-400 {min-height: 400px;}
.min-h-300 {min-height: 300px;}
.min-h-200 {min-height: 200px;}
.min-h-100 {min-height: 100px;}
  
.shad-s{box-shadow:var(--shad-s)}
.shad-m{box-shadow:var(--shad-m)}
.shad-l{box-shadow:var(--shad-l)}
  
/* button */
.btn-action {background-color: var(--action);color: var(--action-ultra-dark);cursor: pointer;border-style: solid;border-color: var(--action);padding: 1em 1.5em;border-width: 0.25rem;border-radius: 2.25rem;text-transform: none;-webkit-text-decoration: none;text-decoration: none;letter-spacing: 0;font-weight: 400;font-style: normal;min-width: 200px;text-align: center;}
.btn-action:hover {background-color: var(--action-hover);border-color: var(--action-hover);color: var(--action-ultra-dark);  }
.btn-primary {background-color: var(--primary);color: var(--black);cursor: pointer;border-style: solid;border-color: var(--primary);padding: 1em 1.5em;border-width: 0.25rem;border-radius: 2.25rem;text-transform: none;-webkit-text-decoration: none;text-decoration: none;letter-spacing: 0;font-weight: 400;font-style: normal;min-width: 200px;text-align: center;}
.btn-primary:hover {background-color: var(--primary-hover);border-color: var(--primary-hover);color: var(--black);  }
.btn-secondary {background-color: var(--secondary);color: var(--secondary-ultra-dark);cursor: pointer;border-style: solid;border-color: var(--secondary);padding: 1em 1.5em;border-width: 0.25rem;border-radius: 2.25rem;text-transform: none;-webkit-text-decoration: none;text-decoration: none;letter-spacing: 0;font-weight: 400;font-style: normal;min-width: 200px;text-align: center;}
.btn-secondary:hover {background-color: var(--secondary-hover);border-color: var(--secondary-hover);color: var(--secondary-ultra-dark);}
.btn-outline {background: transparent;color:var(--black)!important;border:2px solid var(--black);padding: 1em 1.5em;border-width: 0.2rem;border-radius: 2.25rem;text-transform: none;-webkit-text-decoration: none;text-decoration: none;letter-spacing: 0;font-weight: 500;font-style: normal;min-width: 200px;text-align: center;} 
.btn-outline:hover {background: var(--black);color:var(--training)!important;}
.btn-action-treatment {background-color: var(--action-treatment);color: var(--white)!important;cursor: pointer;border-style: solid;border-color: var(--action-treatment);padding: 1em 1.5em;border-width: 0.25rem;border-radius: 2.25rem;text-transform: none;-webkit-text-decoration: none;text-decoration: none;letter-spacing: 0;font-weight: 600;text-transform: uppercase;font-style: normal;min-width: 200px;text-align: center;}
.btn-action-treatment:hover {background-color: var(--action-treatment-hover);border-color: var(--action-treatment-hover);color: var(--black)!important;}

.btn-link {background-color: var(--link-color);color: var(--black);cursor: pointer;border-style: solid;border-color: var(--link-color);padding: 1em 1.5em;border-width: 0.25rem;border-radius: 2.25rem;text-transform: none;-webkit-text-decoration: none;text-decoration: none;letter-spacing: 0;font-weight: 400;font-style: normal;min-width: 200px;text-align: center;}
.btn-link:hover {background-color: var(--link-color-hover);border-color: var(--link-color-hover);color: var(--black);}
.btn-link-outline {background-color: transparent;color: var(--link-color);cursor: pointer;border-style: solid;border-color: var(--link-color);padding: 1em 1.5em;border-width: 0.25rem;border-radius: 2.25rem;text-transform: none;-webkit-text-decoration: none;text-decoration: none;letter-spacing: 0;font-weight: 400;font-style: normal;min-width: 200px;text-align: center;}
.btn-link-outline:hover {background-color: var(--link-color-hover);border-color: var(--link-color-hover);color: var(--black);}

.btn-training-white {background-color: var(--black);color: var(--training)!important;cursor: pointer;border-style: solid;border-color: var(--black);padding: 1em 1.5em;border-width: 0.25rem;border-radius: 2.25rem;text-transform: uppercase;-webkit-text-decoration: none;text-decoration: none;letter-spacing: 0;font-weight: 600;font-style: normal;min-width: 200px;text-align: center;}
.btn-training-white:hover {background-color: var(--grey-light);border-color: var(--grey-light);color: var(--training-hover);}

.btn-small {font-size: var(--text-s)!important;padding: 0.5em 0.9em;border-width: 0.14em;}
.btn-xsmall {font-size: var(--text-xs)!important;padding: 0.5em 0.9em;border-width: 0.14em}

/** cft7 style**/
.wpcf7 input, .wpcf7 textarea {border-radius: 0;border: initial;padding: 0.5em;margin-top: 0.4em;margin-bottom: 0.7em;border-radius: var(--rad-s)}
.wpcf7-form-control.has-spinner.wpcf7-submit {padding: 0.7em 1.9em;border-radius: var(--rad-m);color: var(--primary);cursor: pointer;}
input[type="submit" i] {background: var(--action);}
.wpcf7-list-item {margin: 0;}
.wpcf7-list-item label {background: var(--base);padding: 0.4em!important;display: flex;align-items: flex-start;gap: 0.5em;}

/* Breakpoint 991px portati L */
@media only screen and (max-width: 991px) {
  .grid-6, .grid-5 {grid-template-columns: 3fr}
  .grid-4, .grid-3 {grid-template-columns: 2fr}
  .grid-l-1 {grid-template-columns: 1fr}
  .grid-l-2 {grid-template-columns: 1fr 1fr}
  .gap-l--l {gap: var(--space-l)}
}
  
/* Breakpoint 767px portati M */
@media only screen and (max-width: 767px) {
  .grid-6, .grid-5 {grid-template-columns: 2fr}
  .grid-m-1 {grid-template-columns: 1fr}
}
  
/* Breakpoint 479px portait S */
@media only screen and (max-width: 479px) {
  .grid-2, .grid-s-1  {grid-template-columns: 1fr}
}

.clickable-parent {
  &::after{content: '';display: flex;position: absolute;top: 0;right: 0;bottom: 0;left: 0;cursor: pointer;}
}

.link--skip, .ct-link-text.link--skip {position: absolute;top: 1rem;left: 1rem;transform: translateY(-250%);padding: 0.7em 1.4em;border-width: 0.25rem;border-radius: 0.6rem;text-transform: none;-webkit-text-decoration: none;text-decoration: none;letter-spacing: 0;font-weight: 500;font-style: normal;min-width: 10rem;line-height: 1;background: var(--primary);color: var(--primary-light);text-align: center;}
.link--skip:focus, .ct-link-text.link--skip:focus {transform: translateY(0);}

/* gestione padding inner section */
section.uxd-section, .ct-section, .uxd-section {padding: clamp(2rem, calc(0.7569rem + 5.3039vw), 5rem) clamp(1rem, calc(-0.2431rem + 5.3039vw), 4rem); width: 100%}
section .uxd-inner, .uxd-box, .uxd-inner { max-width: var(--site-width);margin-inline: auto;  width: 100%;}
section.section-pad--none {padding: 0}
main .main-margin>*:first-child>*:first-child {margin-top: var(--header-height)}

/*list element style*/
.list-clean {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.list-clean__element.element-btn a {
  background: var(--primary);
  padding: 0.4em var(--space-m);
  text-align: center;
  margin-top: 0.5em;
  position: relative;
  min-width: 150px;
  border-radius: var(--rad-s);
  color:#fff;
  margin-top: 0.5em;
}
.list-clean__element.element-btn a:hover {
  background: var(--primary-hover);
}