@mixin icon-style($icon-path) {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  min-width: 18px;
  min-height: 18px;
  background-image: url($icon-path);
  background-repeat: no-repeat;
  background-size: contain;
  //background-color: var(--white);
  //border-radius: 50%;
  //border: 4px solid var(--white);
  //box-shadow: var(--shad-m);
}

@for $i from 1 through 5 {
  .happy-#{$i}::before {
      @include icon-style('https://www.a.studiomothers.it/wp-content/uploads/Valenzaa_#{$i}.svg');
  }

  .calm-#{$i}::before {
      @include icon-style('https://a.studiomothers.it/wp-content/uploads/m-arousal_#{$i}.svg');
  }

  .energy-#{$i}::before {
    @include icon-style('https://a.studiomothers.it/wp-content/uploads/Energia_sonnolenza_#{$i}.svg');
  }

}

[data-theme="dark"] {
  @for $i from 1 through 5 {
    .happy-#{$i}::before,
    .calm-#{$i}::before  {
        filter: invert(100%);
    }
  }
}