.box-fasi__alpha {
    background-color: var(--primary);
    color: var(--white);
    margin-top: var(--space-l);
    margin-bottom: var(--space-l);
    padding: var(--space-l) var(--space-m);
    border-top-left-radius: var(--rad-l);
    border-bottom-right-radius: var(--rad-l);
}

.box-fase__alpha--title {background-color: var(--secondary);border-radius: var(--rad-l) 0 var(--rad-l) 0;padding: var(--space-s) 2em;}
.box-fase__alpha--heading {
    text-transform: uppercase;
    text-align: center;
    font-size: var(--text-l);
    margin-bottom: 0;
}
.box-fase__alpha--heading-white {
    text-align: center;
    margin-bottom: 0;
    background-color: var(--white);border-radius: var(--rad-l) 0 var(--rad-l) 0;padding: var(--space-s) 4em;
    color: var(--black);
}


//accordino
.active .title:after {
  transform: rotate(180deg);
}
.title {
  transition: all 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
}
.title:after {
  content: "▼";
  display: inline-block;
  font-size: 12px;
}

 .active .content-box-fasi {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  height: auto;
  transition: all 0.3s, opacity 0.7s;
}

.item {
  margin-bottom: 0.1rem;
}

.content-box-fasi {
  margin: 0 auto;
  overflow: hidden;
  height: 0;
  transition: all 0.2s;
}
.content-box-fasi p {
  font-size: var(--text-l);
  line-height: 1.4;
}
.box-fasi--accordino {
    border-bottom: 1px solid var(--white);
    padding-bottom: 20px;
}

.box-group-fasi__alpha {
    padding-left: var(--space-xl);
}
.box-group-fasi__alpha li::marker {
    font-size: var(--h2);
}

.box-group-fasi__alpha li + li {
    margin-top: 1em;
}

.box-docenti__alpha li {display: flex!important;flex-direction: column;}
.box-docenti__alpha li .forma-1 {
  order: -1
}

