/* Dark and Light 
---------------------------*/
/*dark and light*/
.sun-and-moon>:is(.moon,.sun,.sun-beams) {transform-origin: center center}
.sun-and-moon>:is(.moon,.sun) {fill: var(--icon-fill)}
.theme-toggle:is(:hover,:focus-visible)>.sun-and-moon>:is(.moon,.sun) {fill: var(--icon-fill-hover)}
.sun-and-moon>.sun-beams {stroke: var(--icon-fill);stroke-width: 2px}
.theme-toggle:is(:hover,:focus-visible) .sun-and-moon>.sun-beams {stroke: var(--icon-fill-hover)}
[data-theme=dark] .sun-and-moon>.sun {transform: scale(1.75)}
[data-theme=dark] .sun-and-moon>.sun-beams {opacity: 0}
[data-theme=dark] .sun-and-moon>.moon>circle {transform: translate(-7px)}
@supports (cx: 1) {
    [data-theme=dark] .sun-and-moon>.moon>circle {transform: translate(0); cx: 17}
}
@media (prefers-reduced-motion: no-preference) {
    .sun-and-moon>.sun {transition: transform .5s var(--ease-elastic-3)}
    .sun-and-moon>.sun-beams {transition: transform .5s var(--ease-elastic-4),opacity .5s var(--ease-3)}
    .sun-and-moon .moon>circle {transition: transform .25s var(--ease-out-5)}

    @supports (cx: 1) {
        .sun-and-moon .moon>circle {transition: cx .25s var(--ease-out-5)}
    }

    [data-theme=dark] .sun-and-moon>.sun {transform: scale(1.75);transition-timing-function: var(--ease-3);transition-duration: .25s}
    [data-theme=dark] .sun-and-moon>.sun-beams {transform: rotate(-25deg);transition-duration: .15s}
    [data-theme=dark] .sun-and-moon>.moon>circle {transition-delay: .25s;transition-duration: .5s}
}

.theme-toggle {
    --size: 1.45rem;
    --icon-fill: hsl(210 10% 30%);
    --icon-fill-hover: hsl(354.18deg 100% 50.45%);
    background: none;
    border: none;
    padding: 0;
    inline-size: var(--size);
    block-size: var(--size);
    aspect-ratio: 1;
    border-radius: 50%;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    outline-offset: 5px
}

.theme-toggle>svg {
    inline-size: 100%;
    block-size: 100%;
    stroke-linecap: round
}

[data-theme=dark] .theme-toggle {
    --icon-fill: hsl(35.24deg 100% 66.38%);
    --icon-fill-hover: hsl(35.24deg 73.31% 49.03%)
}

@media (hover: none) {
    .theme-toggle {
        --size: 48px
    }
}

* {
    box-sizing: border-box;
    margin: 0
}

html {
    block-size: 100%;
    color-scheme: light
}

html[data-theme=dark] {
    color-scheme: dark
}

@supports not (color-scheme: dark) {
    html[data-theme=dark] {
        background: #111
    }
}
/* Darl and Light End
--------------- */
