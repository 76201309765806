.link--skip, .ct-link-text.link--skip {
    position: absolute;
    top: 1rem;
    left: 1rem;
    transform: translateY(-250%);
    padding: 0.7em 1.4em;
    border-width: 0.25rem;
    border-radius: 0.6rem;
    text-transform: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    letter-spacing: 0;
    font-weight: 500;
    font-style: normal;
    min-width: 10rem;
    line-height: 1;
    background: var(--primary);
    color: var(--primary-light);
    text-align: center;
}
.link--skip:focus, .ct-link-text.link--skip:focus {
    transform: translateY(0);
}


/* focus */

*:focus, button:focus {
    outline-style: solid;
    outline-color: var(--primary);
    outline-width: 0.16rem;
    outline-offset: 0.2em;
}
*:focus:not(:focus-visible), button:focus:not(:focus-visible) {
  outline: none;
}
/*Disables focus on finger touch for mobile devices*/
:focus:not(:focus-visible) {
  outline: none;
}

/* pulse */
.pulse-infinite {
    animation: pulse-animation 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  /*btn*/
  .pulse:hover, .pulse:focus {
      animation: pulse 1s;
      box-shadow: 0 0 0 1em transparent
  }
  
  @keyframes pulse {
      0% {
          box-shadow: 0 0 0 0 var(--action-trans-40)
      }
  }
  [class*=btn-] {
      transition: all .3s ease
  }
  
  [class*=btn-]:hover,[class*=btn-]:focus {
      animation: pulse 1s;
      box-shadow: 0 0 0 1em transparent
  }
  
  /* pulse cta button */
  .pulsecta {
    animation: pulsecta 1s infinite ease-in-out alternate;
  }
  
  @keyframes pulsecta {
    from { transform: scale(0.8); }
    to { transform: scale(1.2); }
  }
  
  /* link underline*/
  .link-underlined {font-weight: 500;position: relative;display: inline-block;line-height: normal;color: rgb(34,34,34);border: none;cursor: pointer}
  .link-underlined::before {content: "";position: absolute;bottom: -0.2em;left: 0;width: 100%;height: 2px;background-repeat: no-repeat;background-image: linear-gradient(to right, rgb( 34,34,34) 45%, rgba( 34,34,34, 0.3) 55%);background-size: 220% 100%;background-position: 100% 50%;transition: .3s ease-out}
  .link-underlined::after {content: "";display: inline-block;width: 24px;height: 24px;vertical-align: middle;background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-right-thin%3C/title%3E%3Cpath d='M14 16.94V12.94H5.08L5.05 10.93H14V6.94L19 11.94Z' /%3E%3C/svg%3E");background-repeat: no-repeat;background-size: contain;order: -1;}
  .link-underlined:hover::before {background-position: 0% 50%}
  
  /*link move*/
  .link, .underline-info, .nav-side-menu__inner ul.menu a {background-position: 0 bottom,0 bottom;background-size: 0 4px,100% 4px;background-repeat: no-repeat;transition: all 500ms cubic-bezier(.23,1,.32,1); color:var(--white)}
  .link, .nav-side-menu__inner ul.menu a {padding-bottom: 4px;text-decoration: none;font-weight: 600;background-image: linear-gradient(transparent calc(100% - 1px),#212529 1px),linear-gradient(transparent calc(100% - 1px),#dadee2 1px)}
  .link:hover, .clickable-parent:hover .link, .box-slider__auto-nuove:hover .link, .nav-side-menu__inner ul.menu a:hover {background-size: 100% 4px,100% 4px}
  
  /* varie */
  .divider {
      height: 2px;
      min-height: 2px;
      max-height: 2px;
      width: 60px;
      min-width: 60px;
      max-width: 60px;
      background-color: var(--primary);
      margin-top: 12px;
      margin-bottom: 36px;
  }


