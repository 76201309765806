@mixin background-modifiers {
    &--white {
        background: var(--white);
    }

    &--primary {
        background: var(--primary);
    }

    &--secondary {
        background: var(--secondary);
        color: var(--white);
    }

    &--action {
        background: var(--action);
    }
}

.forma-1 {
    overflow: hidden;
    border-radius: 50% 50% 0 50%;
    @include background-modifiers;
}

.forma-2 {
    overflow: hidden;
    border-radius: 50% 0 50% 0;
    @include background-modifiers;
}

.heading-2 {
    overflow: hidden;
    border-radius: var(--rad-l) 0 var(--rad-l) 0;
    text-transform: uppercase;
    padding: 0.4em 4em;
    text-align: center;
    @include background-modifiers;
}


.wp-block-columns .forma-1, .wp-block-columns .forma-2 {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    max-height: 300px;
    text-align: center;
    color: var(--white);
    text-transform: uppercase;
    padding: var(--space-m);
}

.mothers-btn :where(.wp-block-button__link) {
    border-radius: var(--rad-l) 0 var(--rad-l) 0!important;
}

.mothers-btn {
    .btn-gut-action,
    .btn-gut-primary,
    .btn-gut-secondary,
    .btn-gut-black,
    .btn-gut-white,
    .btn-gut-grey,
    .btn-gut-button,
    .btn-gut-outline,
    .btn-gut-outline-link,
    .btn-gut-secondary-outline {
        border-radius: var(--rad-l) 0 var(--rad-l) 0 !important;
        margin-top: 20px;
    }
  }
  
