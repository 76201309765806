/* 
** css simple post loop
*/
.box-post-simple__list {list-style: none;padding-left: 0;}

.box-post-simple__media {width: 100%;padding: var(--space-l);}
.box-post-simple__media img {max-width: 300px;height: 100%;object-fit: cover;aspect-ratio: 1/1;border-radius:var(--rad-l);overflow: hidden}

.box-post-simple a {color:var(--white);}
.box-post-simple a:hover {color:var(--primary);}

.post-home__about img {max-height: 50px;max-width: 200px;object-fit: contain;}


.review-header__featured {width: 30px;height:30px;border-radius: 50%;}




/*  style diari */

.chart-container {
  position: relative;
  width: 1280px;
  height: 130px;
  //background-color: #f0f0f0;
  margin-top: -100px
}

.chart-container-top {
  position: relative;
  width: 1280px;
  background-color: var(--bg-graphic); 
  height: 20px;
  z-index: 2;
}

.time-axis {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.time-axis li {
  text-align: center;
  flex: 1;
  font-size: var(--text-xs)
}

.sleep-period {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: green; /* Colore della linea di sonno */
  height: 30px; /* Altezza della linea di sonno */
  /* Inserisci qui le regole per la larghezza in base all'orario di sonno */
}



.graphic-sleep p {   
    display: flex;
    flex-direction: column;
    align-items: center;
}
.graphic-sleep--legend p {   
    flex-direction: row;
    gap: 15px;
}

.graphic-sleep .wake-down-icon::before {
    content: '\2193'; 
    display: inline-block;
    margin-right: 5px;
    font-size: 23px;
}

.close-light-icon::before {
  display: inline-block;
  margin-right: 5px;
  width: 24px; /* Imposta la larghezza dell'immagine */
  height: 24px; /* Imposta l'altezza dell'immagine */
  background-image: url('https://a.studiomothers.it/wp-content/uploads/light-off.svg');
  background-size: contain; /* Assicura che l'immagine si adatti alle dimensioni del contenitore senza essere tagliata */
  background-repeat: no-repeat; /* Evita la ripetizione dell'immagine */
  content: ""; /* Necessario per generare l'elemento, ma non contiene testo */
}

.open-light-icon::before {
  display: inline-block;
  margin-right: 5px;
  width: 24px; /* Imposta la larghezza dell'immagine */
  height: 24px; /* Imposta l'altezza dell'immagine */
  background-image: url('https://a.studiomothers.it/wp-content/uploads/light-on.svg');
  background-size: contain; /* Assicura che l'immagine si adatti alle dimensioni del contenitore senza essere tagliata */
  background-repeat: no-repeat; /* Evita la ripetizione dell'immagine */
  content: ""; /* Necessario per generare l'elemento, ma non contiene testo */
}

.sonno-notturno-icon::before {
  display: inline-block;
  margin-right: 5px;
  width: 24px; /* Imposta la larghezza dell'immagine */
  height: 24px; /* Imposta l'altezza dell'immagine */
  background-image: url('https://a.studiomothers.it/wp-content/uploads/sonno-notturno.png');
  background-size: contain; /* Assicura che l'immagine si adatti alle dimensioni del contenitore senza essere tagliata */
  background-repeat: no-repeat; /* Evita la ripetizione dell'immagine */
  content: ""; /* Necessario per generare l'elemento, ma non contiene testo */
}

.insonnia-icon::before {
  display: inline-block;
  margin-right: 5px;
  width: 24px; /* Imposta la larghezza dell'immagine */
  height: 24px; /* Imposta l'altezza dell'immagine */
  background-image: url('https://a.studiomothers.it/wp-content/uploads/insonnia.png');
  background-size: contain; /* Assicura che l'immagine si adatti alle dimensioni del contenitore senza essere tagliata */
  background-repeat: no-repeat; /* Evita la ripetizione dell'immagine */
  content: ""; /* Necessario per generare l'elemento, ma non contiene testo */
}

[data-theme="dark"] {
  .insonnia-icon,
  .sonno-notturno-icon,
  .open-light-icon,
  .close-light-icon {
    &::before {
      filter: invert(100%); // Inverte i colori
    }
  }
}

.sonno-notturno, .insonnia, .wake-down, .wake-up, .open-light, .close-light, .box-sol {
  position: relative;
  display: inline-block;
}

.sonno-notturno::after, .insonnia::after, .wake-down::after, .wake-up::after, .open-light::after, .close-light::after, .box-sol::after {
  content: attr(data-text);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;

  /* Stili opzionali per migliorare l'aspetto */
  background-color: var(--white-black);
  color: var(--black-white);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1;

  /* Transizione per un effetto morbido */
  transition: opacity 0.3s, visibility 0.3s;
}

.sonno-notturno:hover::after, .insonnia:hover::after, .wake-down:hover::after, .wake-up:hover::after, .open-light:hover::after, .close-light:hover::after, .box-sol:hover::after {
  visibility: visible;
  opacity: 1;
}

.graphic-sleep .wake-up-icon::before {
    content: '\2191';
    display: inline-block;
    margin-right: 5px;
    font-size: 23px;
}

.box-sleep-graphic--1 {
  order: 1;
}
.box-sleep-graphic--2 {
  order: 3;
}
.box-sleep-graphic--3 {
  order: 2;
}

/* new graphic js*/
.periodo {
    height: 20px;
    display: inline-block;
}

.sonno {
    background-color: #6c1aaf;
    border-bottom: 1px solid #6c1aaf;
    border-left: 1px solid #6c1aaf;
    border-right: 1px solid #6c1aaf;
}

.veglia {

  background-image: linear-gradient(135deg, #ffffff 25%, #cccccc 25%, #cccccc 50%, #ffffff 50%, #ffffff 75%, #cccccc 75%, #cccccc 100%);
  background-size: 15px 15px;
}
.periodo.veglia.giorno {
  background-color: #fefefe;
  background-image: initial;
}

.graficoSonno .periodo:not(:first-child) {
    margin-left: 0px !important;
}

#contenitoreGrafico {
    width: 100%; /* Larghezza al 100% per adattarsi alla larghezza del dispositivo */
    overflow-x: auto; /* Permette lo scorrimento orizzontale */
    z-index: 4;
    position: relative;
    background-color: var(--bg-graphic);
    overflow-y: clip;
    padding-bottom: 15px;
}

.graficoSonno {
    width: 1280px; /* Larghezza fissa per il grafico */
    z-index: 4;
    position: relative;
}

.graficoSonno .periodo {
    margin-top: 15px
}

.post-content .wpgb-facet.wpgb-facet-1 {
    max-width: 300px;
}
.graficoSonno .periodo.sonno.primo {
  position: relative;
}
.graficoSonno .periodo.sonno.primo:first-of-type::before { 
  content: '\2193';
  display: inline-block;
  margin-left: -20px;
  font-size: 23px;
  position: absolute;
  top: -80%;
}
.graficoSonno .last-notte {
  position: relative;
}
.graficoSonno .last-notte::after { 
  content: '\2191';
  display: inline-block;
  margin-right: -20px;
  font-size: 23px;
  position: absolute;
  right: 0;
  top: -100%;
}
/** starts **/

/*stars*/
.stars {
    --percent: calc(var(--rating) / 5 * 100%);
    
    display: inline-block;
    font-size: var(--star-size);
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;
    
    &::before {
      content: '★★★★★';
      letter-spacing: 3px;
      background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }



  /** dashboard diari **/

  .box-diari--dashboard {
    border-radius: 8px; 
    background-color: #F9D423; 
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: box-shadow 0.3s ease; 
    height: 200px;
    text-align: center;
  }
  
  .box-diari--dashboard:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Ombra più marcata al hover */
  }
  
  .box-diari--dashboard::after {
        content: "";
        display: inline-block;
        width: 38px;
        height: 38px;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translateX(-50%); 
        transition: background-color 0.3s ease;
  }
    .box-diari--dashboard.diario-mattina::after {
        background-image: var(--sun-icon);
      }
    
      .box-diari--dashboard.diario-sera::after {
        background-image: var(--moon-icon);
      }
  


  
  .box-diari--dashboard:hover::after {
    color: #E74C3C; /* Cambia colore al hover */
  }
  
  .box-diari--dashboard a {
    text-decoration: none!important;
  }


  #mostra-risultati {
    background-color: #ff9f3b;
    max-width: 200px;
    text-align: center;
    padding: 0.5em;
    border-radius: var(--rad-m);
    font-size: var(--text-m);
  }


  .box-date {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3em;
    border-radius: 7px;
    border: 1px solid #000;
    width: 30px;
    height: 30px;
    background: var(--white);
    color: var(--black)!important;
  }

  .box-day-data {
    background-color: var(--white-black);
    padding: 0.4em;
    border-radius: 4px;
    border: 1px solid var(--black-white);
  }



/* Esempio per mirare specificamente all'input di tipo date all'interno del div */
.box-filtra-data input[type="date"] {
  font-size: var(--text-m);
  padding: 0.4em;
}
.box-filtra-data input[type="submit"] {
background-color: #ff9f3b;
padding: 0 1.3em;
}

.allert-miss-post {
  margin-top: 0.5em;
  color: red;
  text-decoration: underline;
}