@each $grid in (2, 3, 4, 5, 6, '2-1', '1-2', '3-1', '1-3') {
    .single-post .grid-#{$grid}.is-layout-flex, 
    .single-post .grid-#{$grid} {
        display: grid !important;

        @media (max-width: 889px) {
            grid-template-columns: 1fr !important;
        }
    }
}

@media (max-width: 899px) {
    .gut-reverse div:nth-child(2) {
        order: -1;
    }
}
.post-content {
    p,
    h1, h2, h3, h4, h5, h6,
    div {
      max-width: 900px !important;
    }
  }
  

.post-content p + p,
.wp-block-group p + p,
.content-cat__alpha p + p {
  margin-top: 1em;
}

.post-content p + h3 {
    margin-top: 1em;
}

.post-content h2 + h3, .post-content p + h2 {
    margin-top: 0.6em;
}
.post-content ul + p {
    margin-top: 0.95em;
}
.post-content ul li {
    margin-top: 0.6em
}

.gut-fw .wp-block-columns {max-width: 100%!important;}

.gut-fw, .gut-fw-box {padding: clamp(2rem, calc(0.7569rem + 5.3039vw), 5rem) clamp(1rem, calc(-0.2431rem + 5.3039vw), 4rem);width: 100%;}
.gut-fw-box .wp-block-group__inner-container, .gut-fw-box .wp-block-column {max-width:var(--site-width);margin-inline:auto;}


.wp-block-button__link.wp-element-button {border: 0.18em solid var(--link-color)}

.btn-gut-action {border-radius:999px}
.btn-gut-action .wp-block-button__link {background: var(--action);text-decoration:inherit;color:var(--white)!important}
.btn-gut-action .wp-block-button__link:hover {background: var(--action-hover);color:var(--grey)}
.btn-gut-primary .wp-block-button__link {background: var(--primary);border-color: var(--primaary);text-decoration:inherit;color:var(--white)!important}
.btn-gut-primary .wp-block-button__link:hover {background: var(--primary-hover);color:var(--grey)}
.btn-gut-secondary .wp-block-button__link {background: var(--secondary);border-color: var(--secondary);text-decoration:inherit;color:var(--white)!important}
.btn-gut-secondary .wp-block-button__link:hover {background: var(--secondary-hover);color:var(--grey)}

.btn-gut-black .wp-block-button__link {background:var(--black)!important;border: 0.18rem solid var(--black)}
.btn-gut-white .wp-block-button__link {background:var(--white)!important;color:var(--black)!important;border: 0.18rem solid var(--white)}
.btn-gut-grey .wp-block-button__link {background:var(--grey)!important;color:var(--black)!important;border: 0.18rem solid var(--grey)}

.btn-gut-button .wp-block-button__link {background:var(--button)!important;border: 0.18rem solid var(--button)}
.btn-gut-outline .wp-block-button__link {background-color: transparent;border: 0.18rem solid var(--black)}
.btn-gut-outline-link .wp-block-button__link {background-color: transparent;border: 0.18rem solid var(--link-color);color: var(--link-color)}
.btn-gut-outline-link .wp-block-button__link:hover {background-color: var(--link-color);color: var(--black)}
.btn-gut-secondary-outline .wp-block-button__link {background: transparent!important;color:var(--secondary)!important;border:2px solid var(--secondary)!important}


/* blocco box-info__alpha */
.box-info__alpha img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover;
}
.content-grey {
    background: var(--grey-light);
    padding: var(--space-m);
    border-radius: var(--rad-m);
}

