/* before element */
.sede_contatti_alpa__element.address::before, .address::before  {
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    background-image: var(--icon-address);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 8px;
   /*filter: invert(30%) sepia(82%) saturate(4660%) hue-rotate(343deg) brightness(87%) contrast(107%);*/
  filter: invert(24%) sepia(86%) saturate(1580%) hue-rotate(202deg) brightness(98%) contrast(109%);
}

.box-info-alpha__element.address::before{
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: var(--icon-address);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: var(--space-xs)
}

.bg-priamry .box-info-alpha__element.address::before{
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: var(--icon-address);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: var(--space-xs)
}

.sede_contatti_alpa__element.mail::before, .mail::before {
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    background-image: var(--icon-mail);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 8px;
  filter: invert(24%) sepia(86%) saturate(1580%) hue-rotate(202deg) brightness(98%) contrast(109%);
}
.box-info-alpha__element.mail::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: var(--icon-mail);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: var(--space-xs)
}

.sede_contatti_alpa__element.phone::before, .phone::before {
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    background-image: var(--icon-phone);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 8px;
  filter: invert(24%) sepia(86%) saturate(1580%) hue-rotate(202deg) brightness(98%) contrast(109%);
}
.box-info-alpha__element.phone::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: var(--icon-phone);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: var(--space-xs)
}
.sede_contatti_alpa__element.whatsapp::before, .whatsapp::before {
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    background-image: var(--icon-whatsapp);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 8px;
  filter: invert(24%) sepia(86%) saturate(1580%) hue-rotate(202deg) brightness(98%) contrast(109%);
}
.box-info-alpha__element.whatsapp::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: var(--icon-whatsapp);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: var(--space-xs)
}

.bg-primary .sede_contatti_alpa__element.address::before, .address::before  {
  filter: invert(63%) sepia(27%) saturate(3078%) hue-rotate(330deg) brightness(102%) contrast(97%);
}
.bg-primary .sede_contatti_alpa__element.mail::before, .mail::before  {
  filter: invert(63%) sepia(27%) saturate(3078%) hue-rotate(330deg) brightness(102%) contrast(97%);
}
.bg-primary .sede_contatti_alpa__element.phone::before, .phone::before  {
  filter: invert(63%) sepia(27%) saturate(3078%) hue-rotate(330deg) brightness(102%) contrast(97%);
}


.calendar-icon::before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background-image: var(--icon-calendar);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
  filter: invert(36%) sepia(50%) saturate(1934%) hue-rotate(156deg) brightness(96%) contrast(97%);
}