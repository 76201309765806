/* swiper JS
------------------------------------------ */
/* swiper 
.swiper-section {background: #121212;background: -webkit-linear-gradient(to left, #434343, #121212 40%);background: linear-gradient(to left, #434343, #121212 40%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ background-size: contain;background-repeat: no-repeat;background-position: 80% bottom;background-size: 900px auto;padding: 0 clamp(1rem,calc(-0.2431rem + 5.3039vw),4rem);}
.swiper {width: 100%;height: 620px;}
.swiper h1 {font-size: var(--h1-big);}
.swiper-inner {max-width: 1320px;}
.swiper-button-next, .swiper-button-prev {padding: 18px 18px;border-radius: 50%;}
.swiper-button-next:hover, .swiper-button-prev:hover {background: var(--action-hover);background-repeat: no-repeat;background-size: 100% auto;background-position: center;  }
.swiper-button-next:hover {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-right-drop-circle-outline%3C/title%3E%3Cpath d='M2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12M4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12M10,17L15,12L10,7V17Z' fill='%23fb251a'/%3E%3C/svg%3E");}
.swiper-button-next {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-right-drop-circle-outline%3C/title%3E%3Cpath d='M2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12M4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12M10,17L15,12L10,7V17Z' fill='%23fb251a'/%3E%3C/svg%3E"); background-repeat: no-repeat;background-size: 100% auto;background-position: center;  top: 89%;right: 10%;}
.swiper-button-prev:hover {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-left-drop-circle-outline%3C/title%3E%3Cpath d='M22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12M14,7L9,12L14,17V7Z' fill='%23fb251a'/%3E%3C/svg%3E");}
.swiper-button-prev {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-left-drop-circle-outline%3C/title%3E%3Cpath d='M22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12M14,7L9,12L14,17V7Z' fill='%23fb251a'/%3E%3C/svg%3E");background-repeat: no-repeat;background-size: 100% auto;background-position: center;right: 10%;left: inherit;top: 80%;}
.swiper-button-next::after {display: none;}  
.swiper-button-prev::after {display: none;}  
.swiper-slide__inner {max-width: 400px;margin-inline: auto;z-index: 2;position: relative;height: 100%;justify-content: center;align-items: center;display: flex;flex-direction: column;padding-top: 40px;}
.swiper-info {margin-top: var(--space-m);margin-bottom: var(--space-m);max-width: 600px;font-size: var(--text-l);font-weight: 500;}
.swiper-slide__featured {position: absolute;width: 100%;height: 100%;object-fit: cover;aspect-ratio: 1;}
.swiper__hero-img {width: 100%;height: 100%;object-fit: cover;}
.swiper__bg-overlay {background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));position: absolute;top: 0;bottom: 0;left: 0;right: 0;}
.swiper-pagination-bullet-active {transform: scale3d(1.2, 1.2, 1.2);}

*/
.swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }