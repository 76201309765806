/* ------------------------------------------------------------------------- *
/* 2.1 Menu */
/* ------------------------------------------------------------------------- */

.spacer{height: 100px;}

.header{ background-color: var(--site-bg); backdrop-filter: blur(2px); position: fixed; top:0;  left:0; z-index: 9999; width: 100%;padding: 0px 4%;}
.header__content{width: 100%;max-width: var(--header-width);margin-inline:auto;margin: 0 auto; display: flex;justify-content: space-between; padding:10px 0;}

.header__logo,
.header__quick{display: flex; align-items: center;color:var(--site-text-color);font-weight: bold;}
.header__quick a{color:var(--site-text-color);}

.header__content .header__logo-img {max-width: 50px;width: 100%;}
.header__quick ul{list-style-type: none;margin-bottom: 0;}
.header__quick ul li{display: inline-block;}
.header__quick ul li a{padding: 10px 5px;}

.menu,
.menu ul{margin: 0;position: relative;}
.menu li{display: inline-block;position: relative}
.menu li a{color:var(--site-text-color); opacity: 0.8;display: block;padding: 16px;font-size: 15px;font-weight: bold;}

.icon-hamburger{display: none;}

nav.menu--primary {display:flex;align-items:center;}

@media (max-width: 991px) {

  .header__logo-img{width: 140px;height: auto;}

  .menu{   
    position: absolute; top:60px; left:0; background-color: var(--site-bg); width: 100%; height: 100vh;  
    height: 0vh; overflow: hidden;transition: all 1s cubic-bezier(.215, .61, .355, 1);
  }

  .menu li{width: 100%;border-bottom: 1px solid #000}
  .menu-open .menu{height: 100vh;padding: 3%;}

  .icon-hamburger{height: 50px;width: 40px;margin-left: 20px;position:absolute; top: 10px; right:15px; display: block;border:none; background: transparent;}
  .icon-hamburger strong{height: 1px;margin-top:-1px; overflow: hidden;width: 1px;display: block;margin: 0;padding: 0;}
  .icon-hamburger span{height: 2px; width: 30px;background: var(--site-text-color);position: relative;display: block;margin-bottom: 11px;transition: all 0.2s cubic-bezier(.215, .61, .355, 1);}
  
  .menu-open .icon-hamburger span:nth-child(2){transform: rotate(45deg) translateY(9px);}
  .menu-open .icon-hamburger span:nth-child(3){transform: rotate(-45deg) translateY(-9px);}
  .header__content{padding-right: 50px;height: 60px;}
  .header__quick{display: flex; justify-content: flex-end;}
  .header__quick ul{padding-left: 0;}

}

@media (max-width: 400px) {
  .header__quick{display: none;}
} 


/* Menu transparent */
@media (min-width: 992px) {
  .page-template-menu-trasparent .spacer{display: none;}
  .page-template-menu-trasparent .header{background: transparent;transition: all 1s cubic-bezier(.215, .61, .355, 1);}
  .page-template-menu-trasparent .header__content{border:none}
  .page-template-menu-trasparent .header__logo-img,
  .page-template-menu-trasparent .header [class^='icon-']{filter: brightness(0) invert(1);}
  .page-template-menu-trasparent .header__logo{color:#fff}

  .page-template-menu-trasparent .menu li a,
  .page-template-menu-trasparent .header__quick ul li a {color:#fff}

  .page-template-menu-trasparent.scroll-down .header{background: #222;}
}

@media (max-width: 991px) {
  .page-template-menu-trasparent .spacer{height:44px}
  .page-template-menu-trasparent .header__content{border:none}
}

/* Menu black */
@media (min-width: 992px) {
  .page-template-menu-black .spacer{display: none;}
  .page-template-menu-black .header{background: transparent;transition: all 1s cubic-bezier(.215, .61, .355, 1);}
  .page-template-menu-black .header__content{border:none}
 
  .page-template-menu-black.scroll-down .header{background-color: var(--site-bg)}
}

@media (max-width: 991px) {
  .page-template-menu-black .spacer{height:44px}
  .page-template-menu-black .header__content{border:none}
} 


/* Menu Dropdown */
.menu li ul{background: #eee;visibility: hidden; opacity: 0; min-width: 200px; position: absolute; transition: all 0.5s ease; margin-top: 0px;left: 0; display: none;padding: 0;}
.menu li ul > li > a,
.page-template-menu-trasparent .menu li ul > li > a{color:#000}
.menu li ul > li > a:hover {background: #ccc;color:#000}


/* Open Dropdown */
@media (min-width: 992px) {
  .menu li:hover > ul,
  .menu li ul:hover { visibility: visible; opacity: 1; display: block; }
}
.menu li.open-dropdown ul{visibility: visible; opacity: 1; display: block; }

.menu li ul li { clear: both; width: 100%; }
.menu > li.menu-item-has-children > a{position: relative;padding-right: 30px;}

.menu > li.menu-item-has-children > a::after{
  content:" ";width: 30px;height: 30px; background-size:30px;position: absolute;
  top:50%; right: 0; transform: rotate(90deg);margin-top: -15px;
  background-size:100%;
  background-image: url('data:image/svg+xml;utf8,<svg width="50px" height="50px" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"><path fill="black" d="M22.7 34.7l-1.4-1.4 8.3-8.3-8.3-8.3 1.4-1.4 9.7 9.7z"/></svg>');
}

@media (min-width: 992px) {
  .page-template-menu-trasparent .menu > li.menu-item-has-children > a::after{
    background-image: url('data:image/svg+xml;utf8,<svg width="50px" height="50px" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"><path fill="black" d="M22.7 34.7l-1.4-1.4 8.3-8.3-8.3-8.3 1.4-1.4 9.7 9.7z"/></svg>');
    filter: invert(1);
  }
}


@media (max-width: 991px) {
  .menu li ul{position: relative;background: none;}
  .menu li ul li{border:none}
  .menu li ul li a{padding-left: 30px;}
}
