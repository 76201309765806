/*** form gravity ***/
#form-contatto .gform_wrapper {margin: 0!important;}
#form-contatto .gfield input:not([type="checkbox"]):not([type="radio"]), #form-contatto .gform-body textarea {display: block;width: 100%;padding: 0.675rem 1.25rem;font-size: 1rem;font-weight: 600;line-height: 1.5;color: #212529;background-color: #fff;background-clip: padding-box;appearance: none;border-radius: 0.75rem;border: 2px solid #dadee2;}
#form-contatto input[type="file"] {padding:0!important;}
#form-contatto input[type="submit"] {color:var(--white)!important;background: var(--secondary);cursor: pointer;padding: 0.8em 2em;min-width: 230px;border: 2px solid var(--secondary);border-radius: var(--rad-m);font-weight: 600;}
#form-contatto input[type="submit"]:hover {color:var(--white)!important;background: var(--secondary-hover);border: 2px solid var(--secondary-hover);}

#form-contatto .gform_next_button {color:var(--white)!important;background: var(--secondary);cursor: pointer;padding: 0.8em 2em;min-width: 230px;border: 2px solid var(--secondary);border-radius: var(--rad-m);font-weight: 600;}
#form-contatto .gform_next_button:hover {color:var(--white)!important;background: var(--secondary-hover);}

#form-contatto .gform_wrapper.gravity-theme .gfield input.large, .gform_wrapper.gravity-theme .gfield select.large {display: block;width: 100%;padding: 0.675rem 1.25rem;font-size: 1rem;font-weight: 600;line-height: 1.5;color: #212529;background-color: #fff;background-clip: padding-box;appearance: none;border-radius: 0.75rem;border: 2px solid #dadee2;}

#form-contatto li div.ginput_container.ginput_container_select .gfield_select {padding: 0.675rem 1.25rem;font-size:1rem;font-weight:600;line-height:1.5;color:#212529;background-color: #fff;border-radius:0.75rem;border:2px solid #dadee2;}

#form-contatto .ginput_container_select .gfield_select {font-size:1rem;font-weight:500;line-height:2;color:#212529;background-color: #fff;border-radius:0.75rem;border:2px solid #dadee2;}


#form-contatto .name_first {padding-left: 0!important;}
.gform-field-label {padding-bottom: 8px; margin-top:12px}
#form-contatto .gfield_description {color: #bdbcbc}