footer {background-color: var(--base);border-radius: var(--rad-xl) var(--rad-xl) 0 0;color:var(--white)}
footer a {color:var(--black)}
.footer-top {display:grid;grid-template-columns: 2fr 1fr 1fr;gap:var(--space-m); }
@media (max-width: 992px) {
    .footer-top {grid-template-columns: 2fr 2fr;}
}
@media (max-width: 600px) {
    .footer-top {grid-template-columns: 1fr;}
}
footer ul {list-style:none;padding-left: 0;}

.box-info-alpha__list {list-style: none;padding-left: 0;display: flex!important;flex-direction: column;gap:1em}
.box-info-alpha__element {display: flex;align-items: center;}