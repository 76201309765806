.releated-post {
    word-wrap: break-word;
}
.single-post .article .content-gut-post {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: var(--space-l);
}
.content-post-menu__inner {
    position: sticky;
    top: 150px;
}
@media(max-width:900px) {
    .single-post .article .content-gut-post {
        grid-template-columns: 1fr;
    }
    .content-post-menu__inner {
        position: static;
    }
    .cta-fixed {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.listing-menu {
    background-color: var(--black);
    border-radius: var(--rad-s);
    box-shadow: var(--shad-s);
    padding: var(--space-s);
    list-style: none;
}
.listing-menu li:not(:first-child)  {
    margin-top: 0.4em;
}
.listing-menu a {
    color: var(--white);
}

.listing-menu .readed {
    color: var(--grey-med);
}

.listing-menu .readed.active {
    color: var(--secondary);
}